import type { ReactNode } from "react";

type Plan = {
  label: ReactNode;
  note: ReactNode;
  price: string;
  pricePer?: string;
};

export type PlanName = keyof typeof Plans;
type Feature = {
  name: string;
  plans: Record<PlanName, ReactNode>;
};

export const Plans: Record<string, Plan> = {
  Free: {
    label: "Free",
    note: (
      <>
        50 calls per day.
        <br />1 key per developer
      </>
    ),
    price: "$0",
  },
  Standard: {
    label: "Standard",
    note: "0.12 CPM over 225,000 calls per month",
    price: "$25",
    pricePer: "mo",
  },
  Prime: {
    label: "Prime",
    note: "0.15 CPM over 1,800,000 calls per month",
    price: "$250",
    pricePer: "mo",
  },
  Elite: {
    label: "Elite",
    note: "0.22 CPM over 2,400,000 calls per month",
    price: "$500",
    pricePer: "mo",
  },
};

export const Features: Feature[] = [
  {
    name: "Locations",
    plans: { Free: true, Standard: true, Prime: true, Elite: true },
  },
  {
    name: "Current Conditions",
    plans: { Free: true, Standard: true, Prime: true, Elite: true },
  },
  {
    name: "24 Hours Historical Current Conditions",
    plans: { Free: true, Standard: true, Prime: true, Elite: true },
  },
  {
    name: "Daily Forecast",
    plans: {
      Free: "5 Days",
      Standard: "5 Days",
      Prime: "10 Days",
      Elite: "15 Days",
    },
  },
  {
    name: "Hourly Forecast",
    plans: {
      Free: "12 Hours",
      Standard: "12 Hours",
      Prime: "72 Hours",
      Elite: "120 Hours",
    },
  },
  {
    name: "Indices",
    plans: {
      Free: "5 Days",
      Standard: "5 Days",
      Prime: "10 Days",
      Elite: "15 Days",
    },
  },
  {
    name: "Alarms",
    plans: { Free: "5 Days", Standard: "", Prime: "10 Days", Elite: "15 Days" },
  },
  {
    name: "Translations",
    plans: { Free: "", Standard: true, Prime: true, Elite: true },
  },
  {
    name: "Tropical",
    plans: { Free: "", Standard: "", Prime: true, Elite: true },
  },
  {
    name: "Alerts",
    plans: { Free: "", Standard: "", Prime: true, Elite: true },
  },
  {
    name: "Imagery",
    plans: { Free: "", Standard: "", Prime: "", Elite: true },
  },
];
