import type { Sidebar } from "zudoku";

export const sidebar: Sidebar = [
  {
    type: "category",
    label: "Getting Started",
    collapsed: false,
    collapsible: false,
    items: [
      {
        type: "doc",
        label: "Quick Start",
        id: "getting-started",
      },
    ],
  },
  {
    type: "category",
    label: "General Information",
    collapsed: false,
    collapsible: false,
    items: [
      {
        type: "doc",
        label: "About AccuWeather",
        id: "about-accuweather",
      },
      {
        type: "doc",
        label: "API Flow Diagram",
        id: "api-flow-diagram",
      },
      {
        type: "doc",
        label: "Best Practices",
        id: "best-practices",
      },
      {
        type: "doc",
        label: "Data Display Formats",
        id: "data-display-formats",
      },
      {
        type: "doc",
        label: "Languages and Localizations",
        id: "languages-localizations",
      },
      {
        type: "doc",
        label: "Unit Types",
        id: "unit-types",
      },
      {
        type: "doc",
        label: "Weather Icons",
        id: "weather-icons",
      },
      {
        type: "doc",
        label: "Weather Alarm Thresholds",
        id: "weather-alarm-thresholds",
      },
      {
        type: "doc",
        label: "Locations Info",
        id: "locations-info",
      },
      {
        type: "doc",
        label: "Daily Index Info",
        id: "daily-index-info",
      },
      {
        type: "doc",
        label: "Alerts Info",
        id: "alerts-info",
      },
      {
        type: "link",
        label: "System Status",
        href: "https://status.accuweather.com/",
      },
      {
        type: "doc",
        label: "Terms of Use",
        id: "terms-of-use",
      },
    ],
  },
];
