export const AccuWeatherIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 421 421" {...props}>
    <path
      fill="#F36F21"
      d="m391.756 104.537-63.15-13.623-13.974-63.066-61.479 19.774L209.543 0 166.15 47.873l-61.596-19.44-13.64 63.1-63.066 13.974 19.774 61.512L0 210.646l47.856 43.36-19.44 61.595 63.134 13.607 13.957 63.083 61.529-19.741 43.61 47.606 43.36-47.856 61.612 19.44 13.64-63.117 63.05-14.008-19.791-61.495 47.672-43.611-47.889-43.376 19.456-61.596Zm-181.745-39.9c-80.3 0-145.624 65.341-145.624 145.591 0 80.3 65.324 145.624 145.624 145.624 80.267 0 145.59-65.324 145.59-145.624 0-80.25-65.323-145.59-145.59-145.59Zm-91.817 54.71c50.681-50.68 133.121-50.68 183.768-.017 50.63 50.648 50.647 133.071-.017 183.752-50.647 50.647-133.07 50.647-183.734-.017-50.665-50.664-50.665-133.071-.017-183.718Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
