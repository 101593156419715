import { Button, Head, Link } from "zudoku/components";
import { AccuWeatherIcon } from "./AccuWeatherIcon";
import { CheckIcon } from "./CheckIcon";
import { Features, type PlanName, Plans } from "./PlansAndFeatures";
import { useState } from "react";

export const PricingPage = () => {
  const [selectedPlan, setSelectedPlan] = useState<PlanName | undefined>();
  const [minuteCast, setMinuteCast] = useState<"lite" | "pro" | undefined>();

  return (
    <section className="flex flex-col items-center py-12 gap-12 max-w-screen-lg mx-auto">
      <Head>
        <title>Pricing</title>
      </Head>
      <div className="text-center">
        <h1 className="font-extrabold flex items-center text-[40px] justify-center gap-4">
          <AccuWeatherIcon width={40} />
          AccuWeather API Pricing
        </h1>
        <p className="text-muted-foreground max-w-3xl">
          Discover the power of AccuWeather Developer APIs, offering precise and
          real-time weather data for your applications. Our APIs provide
          reliable and accurate weather insights, perfect for enhancing mobile
          apps, websites, and enterprise solutions.
        </p>
      </div>

      <div className="grid grid-cols-5">
        <div className="grid grid-rows-[1fr_min-content] grid-cols-subgrid gap-8 col-start-2 col-span-full gap-x-6 text-center">
          {Object.entries(Plans).map(([key, plan]) => (
            <div className="flex flex-col" key={key}>
              <div className="text-lg text-muted-foreground font-medium">
                {plan.label}
              </div>
              <span className="text-[32px] font-semibold">
                {plan.price}
                {plan.pricePer && (
                  <span className="text-sm text-muted-foreground/80 font-normal">
                    /{plan.pricePer}
                  </span>
                )}
              </span>
              <span className="text-sm text-muted-foreground">{plan.note}</span>
            </div>
          ))}

          {Object.keys(Plans).map((key) => (
            <div key={key} className="flex flex-col gap-1 items-end">
              {key === "Free" ? (
                <Button variant="outline" className="w-full" asChild>
                  <Link to="/documentation" className="no-underline">
                    Get started
                  </Link>
                </Button>
              ) : (
                <Button
                  variant={selectedPlan === key ? "default" : "outline"}
                  className="w-full"
                  onClick={() => setSelectedPlan(key as PlanName)}
                >
                  {selectedPlan === key ? "Added" : "Add to plan"}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-5 text-sm">
        <div className="col-span-full pb-3">
          <div className="flex gap-4">
            <span className="font-medium text-xl">Features</span>
            <Button
              variant="link"
              asChild
              onClick={(e) => {
                document
                  .getElementById("minutecast")
                  ?.scrollIntoView({ behavior: "smooth", block: "start" });
                e.preventDefault();
              }}
            >
              <Link to="#minutecast">MinuteCast</Link>
            </Button>
          </div>
        </div>
        {Features.map((feature) => (
          <div
            key={feature.name}
            className="grid grid-cols-subgrid place-items-center col-span-full px-1 py-3 border-t  hover:bg-accent :bg-accent/40 transition-all"
          >
            <div className="font-medium self-start justify-self-start">
              {feature.name}
            </div>
            {Object.entries(feature.plans).map(([key, value]) => (
              <span key={key}>
                {value === true ? (
                  <CheckIcon className="text-primary" />
                ) : (
                  value
                )}
              </span>
            ))}
          </div>
        ))}
      </div>

      <div className="self-start border rounded p-8 shadow bg-border/20">
        <h2 id="minutecast" className="my-0 mb-4 flex gap-2 items-center">
          <AccuWeatherIcon width={32} />
          MinuteCast
        </h2>
        <p className="text-muted-foreground">
          Experience minute-by-minute weather forecasts with AccuWeather{" "}
          <span className="font-semibold">MinuteCast</span>. This unique feature
          provides precise precipitation type and intensity, start and end times
          for the next two hours, all localized to your exact street address or
          GPS location.
        </p>

        <div className="my-6 border-b" />

        <div className="grid grid-cols-3 grid-rows-[1fr_min-content] gap-x-2 gap-y-6">
          <div className="flex flex-col">
            <div className="text-lg text-muted-foreground font-medium">
              MinuteCast Limited Trial
            </div>
            <span className="text-[32px] font-semibold">Free</span>
            <span className="text-sm text-muted-foreground">
              25 calls per day
              <br />1 key per developer
            </span>
          </div>
          <div className="flex flex-col">
            <div className="text-lg text-muted-foreground font-medium">
              MinuteCast Lite
            </div>
            <span className="text-[32px] font-semibold">
              $25
              <span className="text-sm text-muted-foreground/80 font-normal">
                /mo
              </span>
            </span>
            <span className="text-sm text-muted-foreground">
              $0.18 CPM over 10,000 calls per month
            </span>
          </div>
          <div className="flex flex-col">
            <div className="text-lg text-muted-foreground font-medium">
              MinuteCast
            </div>
            <span className="text-[32px] font-semibold">
              $100
              <span className="text-sm text-muted-foreground/80 font-normal">
                /mo
              </span>
            </span>
            <span className="text-sm text-muted-foreground">
              $0.12 CPM over 675,000 calls per month
            </span>
          </div>
          <Button variant="outline" className="w-full" asChild>
            <Link to="/documentation" className="no-underline">
              Get started
            </Link>
          </Button>
          <Button
            variant={minuteCast === "lite" ? "default" : "outline"}
            className="w-full"
            onClick={() => setMinuteCast("lite")}
          >
            {minuteCast === "lite" ? "Added" : "Add to plan"}
          </Button>
          <Button
            variant={minuteCast === "pro" ? "default" : "outline"}
            className="w-full"
            onClick={() => setMinuteCast("pro")}
          >
            {minuteCast === "pro" ? "Added" : "Add to plan"}
          </Button>
        </div>
      </div>
    </section>
  );
};
