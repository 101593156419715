import type { ZudokuConfig } from "zudoku";
import { sidebar } from "./sidebar";
import { GettingStartedCallout } from "./src/GettingStartedCallout";
import { LandingPage } from "./src/LandingPage";
import { PricingPage } from "./src/PricingPage";

const config: ZudokuConfig = {
  page: {
    pageTitle: "",
    logo: {
      src: {
        light: "/AccuWeatherLogoLight.svg",
        dark: "/AccuWeatherLogoDark.svg",
      },
      width: "200px",
    },
  },
  metadata: {
    favicon:
      "https://developer.accuweather.com/sites/all/themes/accuweather/favicon.ico",
    title: "%s | AccuWeather Developer",
  },
  theme: {
    light: {
      primary: "22.1 89.7% 54.3%",
    },
    dark: {
      background: "0 0% 6.67%",
      primary: "22.1 89.7% 54.3%",
      primaryForeground: "360 100% 100%",
      mutedForeground: "215 9.15% 66.66%",
    },
  },
  customPages: [
    { path: "/home", element: <LandingPage /> },
    { path: "/pricing", element: <PricingPage /> },
  ],
  authentication: {
    type: "auth0",
    domain: "dev-zw2j4kmvkhgbyymd.us.auth0.com",
    clientId: "skwjqoHBYApqgiF5vVJP0sLG2MDVTqzm",
    audience: "https://accuweather-poc.pages.dev/",
    redirectToAfterSignIn: "/settings/api-keys",
    redirectToAfterSignUp: "/settings/api-keys",
  },
  slotlets: {
    "api-keys-list-page": <GettingStartedCallout />,
  },
  topNavigation: [
    { label: "Home", id: "home" },
    { label: "Documentation", id: "documentation" },
    { label: "API Reference", id: "api" },
    { label: "API Keys", id: "settings/api-keys" },
    { label: "Pricing", id: "pricing" },
  ],
  sidebar: { documentation: sidebar },
  redirects: [
    { from: "/", to: "/home" },
    { from: "/documentation", to: "/documentation/getting-started" },
  ],
  apiKeys: {
    enabled: true,
    endpoint: "https://accuweather-poc-main-2d601ae.zuplo.app",
  },
  apis: {
    type: "url",
    input: "https://accuweather-poc-main-2d601ae.zuplo.app/schema",
    navigationId: "api",
  },
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
